import React, { useCallback } from "react";
import Moment from "moment";
import { debounce } from "lodash";

import {
  Box,
  Grommet,
  DataTable,
  DateInput,
  Select,
  Tabs,
  Tab,
  CheckBox,
  Button,
} from "grommet";

import FiltersV4Dates from "./FiltersV4Dates";
function FiltersV4(props) {
  const {
    state,
    onFilterChange,
    dynamicFilters,
    dynamicBooleans,
    noDateInputs,
    dataInfo,
    exportButtonCSV,
  } = props;
  const [searchState, setSearchState] = React.useState({});

  // console.info({ searchState })
  return (
    <Box direction='column' gap='small' pad='small'>
      <Box direction='row' gap='small'>
        <FiltersV4Dates
          state={state}
          onFilterChange={onFilterChange}
          noDateInputs={noDateInputs}
        />
        <Select
          clear
          placeholder='Group By'
          options={state.groupByOpts}
          labelKey='label'
          valueKey='value'
          onChange={({ option }) => {
            onFilterChange({ groupBy: option ? option.value : "" });
          }}
        />
      </Box>
      <Box direction='row' style={{ flexWrap: "wrap", gap: 12 }}>
        {!dynamicFilters || !dynamicFilters.length
          ? null
          : dynamicFilters.map((filterObj) => {
              const values = filterObj.vals || [];
              const options = filterObj.opts
                // .filter((o) => o.value)
                .filter((o) => {
                  if (!o.value || !o.label) {
                    return false;
                  }
                  // if (values.length) {
                  //   if (values.find((v) => v.value === o.value)){
                  //     return false
                  //   }
                  // }
                  let searchVal = searchState[filterObj.name];
                  if (searchVal && o.value) {
                    return (
                      o.value.toLowerCase().includes(searchVal.toLowerCase()) ||
                      (o.label && o.label.toLowerCase().includes(searchVal)) ||
                      (o.sublabel &&
                        o.sublabel.toLowerCase() &&
                        o.sublabel.includes(searchVal))
                    );
                  }
                  return true;
                })
                .map((o) => ({
                  ...o,
                  olabel: o.label,
                  label: (
                    <Box flex='grow' className='opt'>
                      {o.label}
                      {!o.sublabel ? null : (
                        <Box
                          style={{
                            fontSize: 12,
                            color: "gray",
                            height: 10,
                            marginTop: -8,
                          }}
                        >
                          {o.sublabel}
                        </Box>
                      )}
                    </Box>
                  ),
                }));

              return (
                <Box
                  key={`bx_${filterObj.name}`}
                  style={{ flex: "1 0 calc(25% - 10px)" }}
                >
                  <Select
                    key={filterObj.name}
                    clear
                    size='small'
                    multiple
                    placeholder={filterObj.name}
                    options={[
                      // ...values,
                      ...options,
                    ]}
                    labelKey='label'
                    valueKey='value'
                    value={filterObj.vals || undefined}
                    valueLabel={(o) => {
                      if (!o.length) return null;

                      return (
                        <Box style={{ minHeight: 41.5 }} flex='grow' fill>
                          {o.length > 1 ? "Multiple" : o[0]?.label}
                        </Box>
                      );
                    }}
                    onSearch={(val) => {
                      // console.info("search", { val })

                      if (filterObj.onAsyncSearch && val.length > 2) {
                        if (!filterObj.opts.find((o) => o.value.includes(val)))
                          filterObj.onAsyncSearch({ val });
                      }
                      setSearchState((s) => ({ ...s, [filterObj.name]: val }));
                    }}
                    // messages={
                    //     {multiple: }
                    // }
                    closeOnChange={false}
                    onChange={(obj) => {
                      let { value: opts, option } = obj;

                      if (opts.length && !opts[opts.length - 1]) {
                        if (option) {
                          opts[opts.length - 1] = option;
                          // console.info({opts})
                        }
                      }

                      // if (!opts || !opts?.filter((o) => o).length) {
                      //   return;
                      // }
                      const updateObj = {
                        [`${filterObj.name.toLowerCase()}`]:
                          (opts || [])
                            .filter((o) => o)
                            .map(({ value, olabel: label, sublabel }) => ({
                              value,
                              label,
                              sublabel,
                            })) || [],
                      };

                      // console.info({updateObj})

                      onFilterChange(updateObj);
                    }}
                  />
                </Box>
              );
            })}
      </Box>
      <Box direction='row' gap='small'>
        {!dynamicBooleans || !dynamicBooleans.length
          ? null
          : dynamicBooleans.map((dbo) => {
              return (
                <CheckBox
                  size='small'
                  key={dbo.key}
                  checked={dbo.value}
                  label={dbo.name}
                  onChange={(e) =>
                    onFilterChange({ [dbo.key]: e.target.checked })
                  }
                />
              );
            })}
        {exportButtonCSV}
        {dataInfo ? (
          <Box
            align='end'
            justify='end'
            style={{ color: "gray", fontSize: 12 }}
            flex
          >
            {dataInfo}
          </Box>
        ) : undefined}
      </Box>
    </Box>
  );
}

export default FiltersV4;
