import React, { Component } from "react";
import { connect, useSelector } from "react-redux";

import {
  Route,
  Outlet,
  Routes as Switch,
  Navigate as Redirect,
} from "react-router-dom";

// Page Imports
import HomePage from "./pages/HomePage";
import DashboardNew from "./pages/NewDashboard";
import Dashboard from "./pages/DashboardCopy";
import NotFoundPage from "./pages/NotFoundPage";
import FirstPage from "./pages/FirstPage";
import Login from "../containers/Login";
import AppsFlyer from "../containers/AppsFlyer";
import AppsFlyerAdn from "../containers/AppsFlyerAdn/AppsFlyerAdn";
import Networks from "../containers/Networks";
import Advertisers from "../containers/Advertisers/Advertisers";
import CronLogs from "../containers/CronLogs";
import SkadnRedirects from "../containers/SkadnRedirects";
import Offers from "../containers/Offers/Offers";
import ExtOffers from "../containers/ExtOffers/ExtOffers";
import Conversions from "../containers/Conversions/Conversions";
import UserManagement from "../containers/Users/UserManagement";
import TrackRCUserManagement from "../containers/Users/TrackRCUsers";

import Publishers from "../containers/Publishers/Publishers";
import Placements from "../containers/Publishers/Placements";
import Statistics from "../containers/reports/Statistics";
import AppsFlyerCombinedReports from "../containers/AppsflyerCombined/AppsFlyerCombined";
import AppsFlyerCombinedConfig from "../containers/AppsflyerCombined/AppsFlyerCombinedConfig";
import AppsFlyerCombinedEvents from "../containers/AppsflyerCombined/AppsFlyerCombinedEvents";
import AppsFlyerPublishers from "../containers/AppsflyerCombined/AppsFlyerPublishers";
import AdjustReports from "../containers/Adjust/AdjustReports";
import ConvertClicks from "../containers/ConvertClicks/ConvertClicks";
import ConvertClicksJobs from "../containers/ConvertClicks/ConvertClicksJobs";
import ConvertClicksData from "../containers/ConvertClicks/ConvertClicksData";

import AlertsDashboard from "../containers/Alerts/AlertsDashboard";
import AlertsConfig from "../containers/Alerts/AlertsConfig";

import AppsFlyerCombinedCharts from "../containers/AppsflyerCombined/AppsFlyerCombinedCharts";
import AppsFlyerAgencyReports from "../containers/AppsflyerAgency/AppsFlyerAgency";
import AppsFlyerAgencyRawReports from "../containers/AppsflyerAgency/AFAgencyRawData";
import AppsFlyerAgencyRawAcqReports from "../containers/AppsflyerAgency/AFAgencyRawAcqData";
import AppsFlyerAgencyRawFinance from "../containers/AppsflyerAgency/AFAgencyRawFinance";
import AppsflyerPubOptRules from "../containers/AppsflyerPubOpt/AppsflyerPubOptRules";
import Reports from "../containers/Agency/Reports";
import Config from "../containers/Agency/Config";
import Events from "../containers/Agency/Events";
import Af from "../containers/Af/AppsFlyer";
import AdjustConfig from "../containers/Adjust/AdjustConfig";
import AdjustEvent from "../containers/Adjust/AdjustEvent";

const RouteDefs = [
  { path: "/login", element: Login, publicRoute: true },
  { path: "/", element: Dashboard },
  { path: "/dashboard-new", element: DashboardNew },
  { path: "/appsflyer-adn/:activeView", element: AppsFlyerAdn },
  { path: "/appsflyer-agency-reports", element: AppsFlyerAgencyReports },
  { path: "/af-combined/config", element: AppsFlyerCombinedConfig },
  { path: "/af-combined/events", element: AppsFlyerCombinedEvents },
  { path: "/af-combined/reports", element: AppsFlyerCombinedReports },
  { path: "/af-combined/charts", element: AppsFlyerCombinedCharts },
  { path: "/af-combined/publisher-groups", element: AppsFlyerPublishers },
  { path: "/agency/reports", element: Reports },
  { path: "/agency/config", element: Config },
  { path: "/agency/events", element: Events },
  { path: "/agency/agency-raw-reports", element: AppsFlyerAgencyRawReports },
  { path: "/agency/agency-raw-acq-reports", element: AppsFlyerAgencyRawAcqReports },
  { path: "/agency/agency-raw-finance", element: AppsFlyerAgencyRawFinance },
  { path: "/af-agency/agency-pub-opt-rules", element: AppsflyerPubOptRules },
  { path: "/integrations/networks", element: Networks },
  { path: "/advertisers", element: Advertisers },
  { path: "/offers", element: Offers },
  { path: "/external-offers", element: ExtOffers },
  { path: "/conversions", element: Conversions },
  { path: "/publishers", element: Publishers },
  { path: "/user-management", element: UserManagement },
  { path: "/trackrc-user-management", element: TrackRCUserManagement },
  { path: "/convert-service/create-job", element: ConvertClicks },
  { path: "/convert-service/jobs", element: ConvertClicksJobs },
  { path: "/convert-service/clicks", element: ConvertClicksData },
  { path: "/placements", element: Placements },
  { path: "/statistics", element: Statistics },
  { path: "/alerts/alerts-dashboard", element: AlertsDashboard },
  { path: "/alerts/alerts-dashboard/:alertHash", element: AlertsDashboard },
  { path: "/alerts/alerts-config", element: AlertsConfig },
  { path: "/crons", element: CronLogs },
  { path: "/skanRedirects", element: SkadnRedirects },
  { path: "/adjust/adjust-reports", element: AdjustReports },
  { path: "/adjust/adjust-config", element: AdjustConfig },
  { path: "/adjust/adjust-events", element: AdjustEvent },

  { element: NotFoundPage, publicRoute: true },
];

const AGENCY_RESTRICTED_ALLOWED_ROUTES = [
  "/af-combined/reports",
  "/af-combined/config",
  "/af-combined/events",
];
const AGENCY_RESTRICTED_DEFAULT_PATH = "/af-combined/reports";

const ROLES_RESTRICTED_ALLOWED_ROUTES = {
  ACQ_VIEWER: [
    // '/agency/agency-raw-acq-reports'
    '/af-combined/reports'
  ]
}

export const getRolesAllowedRoutes = (roles) => {
  let allowedRoutes = [];

  roles.forEach(role => {
    allowedRoutes = allowedRoutes.concat(ROLES_RESTRICTED_ALLOWED_ROUTES[role] || []);
  })
  return allowedRoutes
}

const PrivateNew = ({ path }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const isAgencyRestricted = (useSelector((state) => state.user.agencies) || [])
    .length;
  
  const isRoleRestricted = (useSelector((state) => state.user.roles)|| "").split(",")
    .filter(r => r !== "USER")



  if (isLoggedIn) {

    // console.info("RESTRICTRED", isRoleRestricted.length, getRolesAllowedRoutes(isRoleRestricted))

    if (isRoleRestricted.length) {
      const allowedRoutes = getRolesAllowedRoutes(isRoleRestricted);
      if (!allowedRoutes.includes(path)) {
        return <Redirect to={allowedRoutes[0]} />;
      }
    }

    if (
      isAgencyRestricted &&
      !AGENCY_RESTRICTED_ALLOWED_ROUTES.includes(path)
    ) {
      return <Redirect to={AGENCY_RESTRICTED_DEFAULT_PATH} />;
    }

    return <Outlet />;
  }

  // "/af-combined/reports"
  return <Redirect to='/login' />;
};

export default function Routes(props) {
  return (
    <Switch>
      {RouteDefs.map((o, i) => {
        const { publicRoute, element: Element, path } = o;
        if (publicRoute) {
          return <Route key={i} exact path={path} element={<Element />} />;
        } else {
          return (
            <Route
              key={i}
              exact
              path={path}
              element={<PrivateNew path={path} />}
            >
              <Route key={`${i}_c`} exact path={path} element={<Element />} />
            </Route>
          );
        }
      })}
    </Switch>
  );
}
