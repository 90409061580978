import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppsFlyerActions from "../../actions/AppsFlyerActions";
import AppsFlyerCombinedActions from "../../actions/AppsFlyerCombinedActions";
import { useNavigate } from "react-router-dom";

import AppBarActions from "../../actions/AppBarActions";
import Moment from "moment";
// import MomentTZ from 'moment-timezone';
// import { DataTableV2 as DataTable } from "../../components/DataTable";
import {DataTable}  from "grommet";
// import ActionButtons from "../../components/ActionButtons";
// import FiltersV2 from "../../components/Filters2/Filters";
import { Text, Box, CheckBox, Tip, Data } from "grommet";

import _ from "lodash";
import Loader from "../../components/Loader";
import Numeral from "numeral";
import Cell from "../../components/Cell";
// import SpeedDial from "../../components/SpeedDial/SpeedDial";
import { Search } from "grommet-icons";
import AppsflyerEntityLabel from "../../components/AppsflyerEntityLabel";
import {
    TextInput,
    Select,
    Button,
    Layer,
    Stack,
    List,
    Heading,
    RadioButtonGroup,
} from "grommet";
import { Spinning } from "grommet-controls";

import {
    Alert,
    Add,
    Edit,
    Duplicate,
    StatusInfo,
    StatusUnknown,
    StatusWarning,
    StatusGoodSmall,
} from "grommet-icons";
import ReadField from "../../components/ReadField";
import useSWR from "swr";

import { getFetcher } from "../../utils/fetch";
import date from "@hapi/joi/lib/types/date";


const dateRenderer = (name) => (row) => {
    return <Cell>{Moment(row[name]).format("YYYY-MM-DD HH:mm:ss")}</Cell>;
}
const defaultRenderer = (name) => (row) => {
    return <Cell>{row[name]}</Cell>;
}

function Config(props) {

    const { data, error } = useSWR("/api/appsflyer/agency-pub-opt", getFetcher)

    console.info(data)


    return (
        <Box
            flex
            fill
            width='100%'
            margin='none'
            overflowY='auto'
            alignSelf='center'
        >
            <DataTable
                columns={[
                    {
                        header: 'Rule ID',
                        property: 'rule_id',
                        render: defaultRenderer("rule_id")
                    },
                    {
                        header: 'App Bundle',
                        property: 'app_bundle',
                        render: defaultRenderer("app_bundle")

                    },
                    {
                        header: 'Agency ID',
                        property: 'agency_id',
                        render: defaultRenderer("agency_id")

                    },
                    {
                        header: 'Desired Ratio',
                        property: 'desired_ratio',
                        render: defaultRenderer("desired_ratio")

                    },
                    {
                        header: 'Enabled',
                        property: 'enabled',
                        render: defaultRenderer("enabled")

                    },
                    {
                        header: 'Publishers',
                        property: 'publisher',
                        render: ({ publisher }) => {
                            return <Cell>{!publisher ? "ALL" : publisher.length}</Cell>;
                        }
                    },
                    {
                        header: 'Source',
                        property: 'source',
                        render: defaultRenderer("source")
                    },
                    {
                        header: 'Created Time',
                        property: 'created_time',
                        render: dateRenderer("created_time")
                    },
                    {
                        header: 'Updated Time',
                        property: 'updated_time',
                        render: dateRenderer("updated_time")
                    },
                    {
                        header: 'Start Time',
                        property: 'start_time',
                        render: dateRenderer("start_time")

                    },
                    {
                        header: 'Snapshots',
                        property: 'snapshots',
                        render: ({ snapshots }) => {
                            return <Cell>{snapshots.length}</Cell>
                        }
                    },
                ]}
                data={data}
                rowDetails={({ snapshots }) => {

                    return <Box full>
                        <Box pad="large">
                            <DataTable
                                columns={[
                                    {
                                        header: 'Snapshot ID',
                                        property: 'snapshot_id',
                                        render: defaultRenderer("snapshot_id")
                                    },
                                    {
                                        header: 'Publisher',
                                        property: 'publisher',
                                        render: defaultRenderer("publisher")
                                    },
                                    {
                                        header: 'Acq',
                                        property: 'acq',
                                        render: defaultRenderer("acq")
                                    },
                                    {
                                        header: 'Post',
                                        property: 'post',
                                        render: defaultRenderer("post")
                                    },
                                    {
                                        header: 'Ratio',
                                        property: 'ratio',
                                        render: defaultRenderer("ratio")
                                    },
                                    {
                                        header: 'Post Disabled',
                                        property: 'post_disabled',
                                        render: defaultRenderer("post_disabled")
                                    },
                                    {
                                        header: 'Created Time',
                                        property: 'created_time',
                                        render: dateRenderer("created_time")
                                    },
                                    {
                                        header: 'Updated Time',
                                        property: 'updated_time',
                                        render: dateRenderer("updated_time")
                                    },
                                ]}
                                data={snapshots}
                            />
                        </Box>
                    </Box>;
                }}
            />

        </Box>
    );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
    const browser = state.browser;
    const adnNetworks = state.appsflyer_adn.constants;
    const agencies = state.appsflyer.constants;


    const { appsflyer_combined: appsflyer } = state;

    return { appsflyer, browser, adnNetworks, agencies };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        Object.assign(
            {},
            AppsFlyerActions,
            AppBarActions,
            AppsFlyerCombinedActions
        ),
        dispatch
    );
}



export default connect(mapStateToProps, mapDispatchToProps)(Config);
